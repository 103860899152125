import {BREAK_POINTS} from '../settings';
import {myDevice} from './device';
import trigger from './customEvent';

export default function() {
  //ハンバーガーメニュークリックと画面サイズに応じて aria 属性とクラス名の切り替え
  let elHeader,elWrapper,elBtns,elBtn,elTarget,links;

  function init() {
    elHeader = document.querySelector('.l-header');
    elWrapper = document.querySelector('.js-menu-target');
    links = elWrapper.querySelectorAll('a');
    elBtn = document.querySelector('.js-btnMenu');
    elBtns = document.querySelectorAll('.js-btnMenu');
    const target = document.querySelector('.js-btnMenu').getAttribute('aria-controls');

		elTarget = document.querySelector('.'+target);
    if(window.innerWidth > BREAK_POINTS.ltSP) {
      show();
    } else {
      hide();
    }

    [].forEach.call(links, link => {
      link.addEventListener('click', function() {
        trigger(elBtn, 'click');
      })
    });
    [].forEach.call(elBtns, el => {
      el.addEventListener('click', clickHandle)
    })
  }

	function setElmsAttribute(elms, name, value) {
		[].forEach.call(elms, el => {
			el.setAttribute(name, value);
		});
	}

	function hide() {
		setElmsAttribute(elBtns, "aria-expanded", false);
		elTarget.setAttribute("aria-hidden", true);
    elHeader.classList.remove('is-active');
		[].forEach.call(elBtns, el => {
			el.classList.remove('is-active');
		});
	}

	function show() {
		setElmsAttribute(elBtns, "aria-expanded", true);
		elTarget.setAttribute("aria-hidden", false);
    elHeader.classList.add('is-active');
		[].forEach.call(elBtns, el => {
			el.classList.add('is-active');
		});
	}

  function toggleAtributes() {
    if(elTarget.getAttribute('aria-hidden') == "true") {
			show();
    } else {
			hide();
    }
  }

  function clickHandle(e) {
    e.preventDefault();
    toggleAtributes();
  }

  window.addEventListener('DOMContentLoaded', init);
	if(!myDevice.isTouchDevice) {
		window.addEventListener('resize', init);
	}
}
